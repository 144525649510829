import React, { useEffect, useState } from 'react';

export const FetchData: React.FC = (props) => {
  const [forecasts, setForecasts] = useState([]);
  const [loading, setLoading] = useState(true);

  // populateWeatherData();
  useEffect(() => {
    (async function loadWeatherForecast() {
      try {
        const response = await fetch('api/categories');
        const data = await response.json();
        setForecasts(data);
        setLoading(false);
      } catch (ex) {
        setLoading(false);
      }
    })();
  }, []);

  const renderForecastsTable = (forecasts) => {
    return (
      <table className="table table-striped" aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map((forecast) => (
            <tr key={forecast.categoryId}>
              <td>{forecast.categoryId}</td>
              <td>{forecast.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  let contents = loading ? (
    <p>
      <em>Loading...</em>
    </p>
  ) : (
    renderForecastsTable(forecasts)
  );

  return (
    <div>
      <h1 id="tableLabel">Weather forecast</h1>
      <p>This component demonstrates fetching data from the server.</p>
      {contents}
    </div>
  );
};
