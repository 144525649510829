import React, { useCallback } from 'react';
import Texty from 'rc-texty';

interface HeroProps {
  texts: string[];
  duration?: number;
}

export const SpinText: React.FC<HeroProps> = ({
  texts = [],
  duration = 1000,
}) => {
  const [index, setIndex] = React.useState(0);
  const [show, setShow] = React.useState(true);

  const handleOnEnd = useCallback(() => {
    if (show) {
      setTimeout(() => setShow(false), 5000);
    } else {
      let next = index + 1;
      if (next >= texts.length) next = 0;

      setTimeout(() => {
        setIndex(next);
        setShow(true);
      }, duration);
    }
  }, [index, show, duration, texts.length]);

  if (texts.length === 0) return null;
  const text = show ? texts[index] : undefined;

  return (
    <div style={{ height: '40px', fontSize: '1.8rem' }}>
      <Texty
        type="mask-top"
        mode="smooth"
        duration={duration}
        onEnd={handleOnEnd}
      >
        {text}
      </Texty>
    </div>
  );
};
