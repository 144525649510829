import React from 'react';
import { Route } from 'react-router';
import { Flex } from '@chakra-ui/react';
import { Layout } from './components/Layout';
import routes from './AppRoutes';
import Header from './components/Header';
import { Home } from './components/Home';

import './custom.scss';

export const App: React.FC = (props) => {
  return (
    <Flex direction="column" align="center" maxW="5xl" m="0 auto" {...props}>
      <Header />
      <Route exact path="/">
        <Home />
      </Route>
      {routes.map(({ path, Component }, key) => (
        <Route
          exact
          path={path}
          key={key}
          render={() => (
            <Layout>
              <Component />
            </Layout>
          )}
        />
      ))}
    </Flex>
  );
};
