import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FaMastodon, FaTwitterSquare, FaGithubSquare, FaStackOverflow } from 'react-icons/fa';
import Logo from './Logo';

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuText = ({ children, isLast = false, ...rest }) => (
  <Text
    mb={{ base: isLast ? 0 : 8, sm: 0 }}
    mr={{ base: 0, sm: isLast ? 0 : 8 }}
    display="block"
    color={['white', 'white', 'primary.500', 'primary.500']}
    _hover={{
      color: [
        'primary.100',
        'primary.100',
        'primary.600',
        'primary.600',
      ],
    }}
    {...rest}
  >
    {children}
  </Text>
);

const MenuLink = ({ to = '/', children, ...rest }) => (
  <MenuText {...rest}>
    <Link to={to}>{children}</Link>
  </MenuText>
);

const MenuAnchor = ({ url, children, title = '', rel = 'noopener', target = '_self', ...rest }) => (
  <MenuText {...rest}>
    <a href={url} rel={rel} target={target} title={title}>
      {children}
    </a>
  </MenuText>
);

const Header = (props) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      bg={['primary.500', 'primary.500', 'transparent', 'transparent']}
      color={['white', 'white', 'primary.700', 'primary.700']}
      {...props}
    >
      <Flex align="center">
        <Logo w="200px" />
      </Flex>

      <Box display={{ base: 'block', md: 'none' }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </Box>

      <Box
        display={{ base: show ? 'block' : 'none', md: 'block' }}
        flexBasis={{ base: '100%', md: 'auto' }}
      >
        <Flex
          align="center"
          justify={['center', 'space-between', 'flex-end', 'flex-end']}
          direction={['column', 'row', 'row', 'row']}
          pt={[4, 4, 0, 0]}
        >
          <Flex>
            <MenuLink to="/">Home</MenuLink>
            <MenuLink to="/projects">Projects</MenuLink>
            <MenuAnchor url="https://blog.denny.dev">
              Blog
            </MenuAnchor>
          </Flex>
          <Flex>
            <MenuAnchor mr={2} url="https://hachyderm.io/@denny" title="Mastodon" rel="me noopener noreferrer" target="_blank">
              <FaMastodon size="1.8em" />
            </MenuAnchor>
            <MenuAnchor mr={2} url="https://github.com/dennyferra" title="GitHub" rel="noopener noreferrer" target="_blank">
              <FaGithubSquare size="1.8em" />
            </MenuAnchor>
            <MenuAnchor mr={2} url="https://twitter.com/dennyferra" title="Twitter" rel="noopener noreferrer" target="_blank">
                <FaTwitterSquare size="1.8em" />
            </MenuAnchor>
            <MenuAnchor url="https://stackoverflow.com/users/113474/denny-ferrassoli" title="Stack Overflow" rel="noopener noreferrer" target="_blank" isLast>
              <FaStackOverflow size="1.8em" />
            </MenuAnchor>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
