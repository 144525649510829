import { Box } from '@chakra-ui/react';
import React from 'react';

export const Layout: React.FC = (props) => {
  return (
    <Box minW="100%" pl={[4, 4, 12, 16]} pr={[4, 4, 12, 16]}>
      {props.children}
    </Box>
  );
};
