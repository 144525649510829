import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Text,
  Center,
} from '@chakra-ui/react';
import { SpinText } from './SpinText';

interface HeroProps {
  title?: string;
  subtitle?: string;
  image?: string;
  ctaText?: string;
  ctaLink?: string;
}

export const Hero: React.FC<HeroProps> = ({
  title = "Hi! I'm Denny a",
  subtitle = "This here is the sub-header section, fantastic isn't it!?",
  image = 'https://source.unsplash.com/collection/404339/550x400',
  ctaText = 'Do something crazy!',
  ctaLink = '/fetch-data',
  ...rest
}) => (
  <Flex
    align="center"
    justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
    direction={{ base: 'column-reverse', md: 'row' }}
    wrap="nowrap"
    minH="70vh"
    mb={16}
    {...rest}
  >
    <Stack
      spacing={4}
      w={500}
      pl={{ base: 0, md: 8 }}
      align={['center', 'center', 'flex-start', 'flex-start']}
    >
      <Heading
        as="h1"
        size="xl"
        fontWeight="bold"
        color="primary.800"
        textAlign={['center', 'center', 'left', 'left']}
      >
        {title}
        <SpinText texts={['Software Engineer', 'Indie Hacker', 'Wantrepreneur']} />
      </Heading>
      <Heading
        as="h2"
        size="md"
        color="primary.800"
        opacity="0.8"
        fontWeight="normal"
        lineHeight={1.5}
        textAlign={['center', 'center', 'left', 'left']}
      >
        {subtitle}
      </Heading>
      <Link to={ctaLink}>
        <Button
          colorScheme="primary"
          borderRadius="8px"
          py="4"
          px="4"
          lineHeight="1"
          size="md"
        >
          {ctaText}
        </Button>
      </Link>
      <Text
        fontSize="xs"
        mt={2}
        textAlign="center"
        color="primary.800"
        opacity="0.6"
      >
        No credit card required.{/* (https://www.zach.so/)*/}
      </Text>
    </Stack>
    <Box
      mb={{ base: 12, md: 0 }}
      pr={{ base: 14, md: 8 }}
      pl={{ base: 12, md: 8 }}
      w="100%"
      height="auto"
    >
      <Center>
        <Image src={image} w={550} rounded="1rem" shadow="2xl" />
      </Center>
    </Box>
  </Flex>
);
