import React from 'react';
import { Button, chakra, Heading, Wrap, WrapItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

const ProjectButton = chakra(Button, {
  baseStyle: {
    width: '180px',
    height: '80px',
  },
});

export const Projects: React.FC = (props) => {
  let { url } = useRouteMatch();

  return (
    <>
      <Heading mb={12}>Projects</Heading>
      <Wrap justify="center">
        <Link to={`${url}/pizza`}>
          <WrapItem>
            <ProjectButton bg="red.200">Pizza Calculator</ProjectButton>
          </WrapItem>
        </Link>
        <WrapItem>
          <ProjectButton bg="green.200">Box 2</ProjectButton>
        </WrapItem>
        <WrapItem>
          <ProjectButton bg="tomato">Box 3</ProjectButton>
        </WrapItem>
        <WrapItem>
          <ProjectButton bg="blue.200">Box 4</ProjectButton>
        </WrapItem>
      </Wrap>
    </>
  );
};
