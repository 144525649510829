import { Counter } from './components/Counter';
import { FetchData } from './components/FetchData';
import { Projects } from './components/projects/Projects';
import { Pizza } from './components/projects/Pizza';

const routes = [
  { path: '/counter', name: 'How It Works', Component: Counter },
  { path: '/fetch-data', name: 'Features', Component: FetchData },
  { path: '/projects', name: 'Projects', Component: Projects },
  { path: '/projects/pizza', name: 'Pizza Calculator', Component: Pizza },
];

export default routes;
