import {
  Badge,
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  NumberInput,
  NumberInputField,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

interface IResult {
  total1: string;
  total2: string;
}

export const Pizza: React.FC = () => {
  const formatDollar = (val) => `$` + val;
  const parseDollar = (val) => val.replace(/^\$/, '');
  const formatInch = (val) => val + '"';
  const parseInch = (val) => val.replace(/"/, '');

  const [p1diameter, setP1Diameter] = useState('15');
  const [p1cost, setP1Cost] = useState('14.99');
  const [p2diameter, setP2Diameter] = useState('18');
  const [p2cost, setP2Cost] = useState('16.99');
  const [result, setResult] = useState<IResult | undefined>();

  const calculate = useCallback(() => {
    const p1d = parseFloat(p1diameter);
    const p1c = parseFloat(p1cost);
    const p2d = parseFloat(p2diameter);
    const p2c = parseFloat(p2cost);

    const area1 = (p1d / 2) ** 2 * 3.14;
    const total1 = (p1c / area1).toFixed(2);

    const area2 = (p2d / 2) ** 2 * 3.14;
    const total2 = (p2c / area2).toFixed(2);

    setResult({
      total1,
      total2,
    });
  }, [p1diameter, p1cost, p2diameter, p2cost]);

  return (
    <>
      <Heading mb={12}>Pizza Value Calculator</Heading>
      <Box px={{ md: 24, sm: 10 }}>
        <HStack>
          <FormControl>
            <FormLabel htmlFor="pizza1d">Pizza 1 Diameter</FormLabel>
            <NumberInput
              onChange={(valueString) => setP1Diameter(parseInch(valueString))}
              value={formatInch(p1diameter)}
            >
              <NumberInputField id="pizza1d" />
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="pizza1c">Pizza 1 Cost</FormLabel>
            <NumberInput
              onChange={(valueString) => setP1Cost(parseDollar(valueString))}
              value={formatDollar(p1cost)}
            >
              <NumberInputField id="pizza1c" />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack mt={4}>
          <FormControl>
            <FormLabel htmlFor="pizza2d">Pizza 2 Diameter</FormLabel>
            <NumberInput
              onChange={(valueString) => setP2Diameter(parseInch(valueString))}
              value={formatInch(p2diameter)}
            >
              <NumberInputField id="pizza2d" />
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="pizza2c">Pizza 2 Cost</FormLabel>
            <NumberInput
              onChange={(valueString) => setP2Cost(parseDollar(valueString))}
              value={formatDollar(p2cost)}
            >
              <NumberInputField id="pizza2c" />
            </NumberInput>
          </FormControl>
        </HStack>

        <Collapse in={!!result} animateOpacity>
          {result && (
            <StatGroup mt={8}>
              <Stat>
                <StatLabel>Pizza 1</StatLabel>
                <StatNumber>${result.total1}/sq inch</StatNumber>
                <StatHelpText>
                  {result.total1 < result.total2 && (
                    <Badge colorScheme="green">Best Value</Badge>
                  )}
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>Pizza 2</StatLabel>
                <StatNumber>${result.total2}/sq inch</StatNumber>
                <StatHelpText>
                  {result.total2 < result.total1 && (
                    <Badge colorScheme="green">Best Value</Badge>
                  )}
                </StatHelpText>
              </Stat>
            </StatGroup>
          )}
        </Collapse>

        <Button colorScheme="blue" mt={8} onClick={calculate}>
          Calculate
        </Button>
      </Box>
    </>
  );
};
